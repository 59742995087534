import React, {forwardRef} from 'react';
import styles from './Input.module.css';

interface InputProps {
  type?: string;
  placeholder?: string;
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  autoFocus?: boolean;
  radius?: string; // e.g., "4px", "8px", or "50%" for a circular input
  label?: string;
  labelPlacement?:
    | 'above'
    | 'left'
    | 'right'
    | 'outside'
    | 'inside'
    | 'outside-left'
    | 'outside-search'
    | undefined;
  isReadOnly?: boolean;
  startContent?: any;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      placeholder = '',
      value,
      onValueChange,
      className = '',
      autoFocus = false,
      radius = '4px',
      label,
      labelPlacement = 'above',
      isReadOnly = false,
      startContent,
      ...props
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(e.target.value);
    };

    // Apply styles based on label placement
    const containerStyles = {
      above: 'flex flex-col', // Label above the input
      left: 'flex items-center', // Label to the left of the input
      right: 'flex items-center flex-row-reverse', // Label to the right of the input
      outside: 'flex flex-col ', // Label outside, above with extra margin below
      inside: 'flex flex-col',
      'outside-left': 'flex items-center mb-2', // Label outside to the left with extra margin
      'outside-search':
        'group flex flex-col data-[hidden=true]:hidden w-full group relative justify-end data-[has-label=true]:mt-[calc(theme(fontSize.small)_+_10px)] col-span-2',
    };

    return (
      <div
        className={
          labelPlacement === 'inside'
            ? ''
            : containerStyles[labelPlacement || 'above']
        }
      >
        {/* Conditionally render the label based on labelPlacement */}
        {label && labelPlacement !== 'inside' && (
          <label
            className={`font-bold ${labelPlacement === 'outside-left' || labelPlacement === 'left' ? 'mr-2' : ''} 
                       ${labelPlacement === 'outside' ? 'mb-1' : ''}`}
          >
            {label}
          </label>
        )}
        <div className={`${styles.inputContainer}`}>
          {startContent && <div style={{margin: '0.5rem'}}>{startContent}</div>}
          <input
            type={type}
            placeholder={labelPlacement === 'inside' ? label : placeholder}
            value={value}
            onChange={handleChange}
            autoFocus={autoFocus}
            readOnly={isReadOnly}
            className={`border px-3 py-2 rounded-md ${className} ${styles.inputBox}`}
            ref={ref}
            {...props}
          />
        </div>
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
