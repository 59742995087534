import Input from '../components/Input/Input';
import {ReactNode, useCallback} from 'react';

type CityIDInputProps = {
  value?: string | (readonly string[] & string) | undefined;
  onValueChange: (value: string) => void;
  placeholder?: string;
  labelPlacement?:
    | 'above'
    | 'left'
    | 'right'
    | 'outside'
    | 'inside'
    | 'outside-left'
    | 'outside-search';
  startContent?: ReactNode;
  onAccept?: (value: string) => void;
  autoFocus?: boolean;
  className?: string;
};

export default function CityIDInput(props: CityIDInputProps) {
  const handleValueChange = useCallback(
    (value: string) => {
      // Call the original onValueChange
      props.onValueChange(value);

      // If Enter is pressed and onAccept is provided, call it
      if (props.onAccept && value === props.value) {
        props.onAccept(value);
      }
    },
    [props.onValueChange, props.onAccept, props.value]
  );

  return (
    <Input
      className={props.className}
      type="search"
      radius="none"
      placeholder={props.placeholder}
      value={props.value || ''}
      labelPlacement={props.labelPlacement}
      startContent={props.startContent || ''}
      onValueChange={handleValueChange}
      autoFocus={props.autoFocus}
    />
  );
}
